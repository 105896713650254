/* CodeLogin.css */

/* 
  Full-screen (or more accurately "full-min-screen") container 
  to center the code login on the page 
*/
.outer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* replaced height: 100vh */
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
  text-align: center;
  max-width: 100%;
  overflow-x: hidden; /* safeguard for mobile Safari */
}

/* Logo styling */
.logo {
  display: block;
  margin: 0 auto;
  max-width: 150px;
  height: auto;
  margin-bottom: 20px;
}

/* Login box styling */
.code-container {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 350px;
  width: 90%;
  margin: 0 auto;
  box-sizing: border-box;
}

.code-container h2 {
  color: #853a38;
  margin-bottom: 20px;
}

.code-container input {
  padding: 12px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.code-container input:focus {
  border-color: #853a38;
  outline: 1px solid #853a38;
}

.code-container button {
  padding: 12px;
  margin-top: 12px;
  background-color: #853a38;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.code-container button:hover {
  background-color: #6b2d2d;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .code-container {
    width: 90%;
  }
}
