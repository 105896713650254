/* Header styling */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0; /* Reduced padding */
  margin: 0; /* Removed margins */
  background-color: transparent;
  width: 100%;
}

.header-logo {
  height: 100px;
  width: auto;
  margin: 0; /* Removed margin */
}
