/* Reset & Global Styles */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Important for iOS Safari, ensures no horizontal scroll due to scrollbar: */
  overflow-x: hidden;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* Root container for entire React app */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* 
  Main .App container 
  - Using min-height to avoid iOS Safari bottom bar issues 
  - Centered content
*/
.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* or center, but we have a top area (Header) */
  align-items: center;
  min-height: 100vh;
  text-align: center;
  color: #853a38;

  /* Background fallback color if image fails */
  background-color: #853a38;

  /* Optional background image if you want it site-wide.
     If you prefer a dedicated background on CodeLogin only, remove it here. */
  background-image: url('./images/BackPassBackgroundGuy.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Main content wrapper to push footer down if needed */
.main-content {
  flex: 1;
  width: 100%;
  display: flex; /* If you want to center items within main content, do so here */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

/* Footer styling */
.footer {
  text-align: center;
  padding: 10px;
  background-color: transparent;
  border-top: none;
  width: 100%;
  color: white;
  position: relative;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .main-content {
    padding: 10px;
  }
}
